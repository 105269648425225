<template>
    <Card>
        <div slot="title">{{ title }}</div>
        <div class="form_item">
            <div class="form_label must_input">用户组名</div>
            <Input v-model.trim="formData.groupname" class="width_300" maxlength="15" show-word-limit></Input>
        </div>
        <div class="form_item">
            <div class="form_label align_self_start">排序</div>
            <InputNumber v-model.trim="formData.listorder" :min="0" :max="99999" :precision="0" class="width_300"></InputNumber>
        </div>
        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">取消</i-button>
            <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirmEdit">确认</i-button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import { reqSaveGroup } from '@/lib/request/auth';

export default {
    name: 'serviceManagerAdd',
    components: {
        //
    },
    data() {
        return {
            title: null,
            formData: {
                id: null,
                groupname: null,
                listorder: null,
            },
        };
    },
    mounted() {
        this.routeParamsInjectFormData();
        if (hm.isNotEmptyString(this.formData.listorder)) {
            this.formData.listorder = +this.formData.listorder || 0;
        }
    },
    methods: {
        //取消
        onCloseEdit() {
            this.$router.back();
        },
        //确认
        onConfirmEdit() {
            this.showAjaxLoading();
            let params = window.structuredClone(this.formData);

            let { groupname } = params || {};
            params.listorder = params.listorder || 0;

            let vs = [{ value: groupname, tip: '请输入用户组名' }];
            hm.validateForm(vs)
                .then(() => {
                    console.log('onConfirmEdit', params);
                    reqSaveGroup(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.goUrl('/sysManagerRoleList');
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
